<template>
  <div class="card">
    <div class="card-body general-details">
      <h4 class="font-weight-bold d-block mb-4">
        Profile
      </h4>
      <ifac-loader class="p-5" v-if="busy"></ifac-loader>
      <div v-else>
        <div class="row align-items-start input-row">
          <div class="col-12 col-sm-auto">
            <img
              v-if="affiliate.avatar"
              :src="affiliate.avatar"
              :alt="nameNoTitle"
              width="100"
              height="100"
              class="rounded-lg mb-4"
            />
            <div
              v-else
              style="width: 100px; height: 100px"
              class="
                rounded-lg
                bg-light
                d-flex
                justify-content-center
                align-items-center
                text-primary
                font-weight-bold
              "
            >
              <div>
                {{ nameNoTitle | acronym }}
              </div>
            </div>
          </div>

          <div class="col-auto">
            <div>
              <p class="font-weight-bold">Title</p>
              <p class="">{{ affiliate.title || "-" }}</p>
            </div>
          </div>
          <div class="col-auto">
            <div>
              <p class="font-weight-bold">Name</p>
              <p class="">{{ affiliate.name || "-" }}</p>
            </div>
          </div>
          <div class="col">
            <div>
              <p class="font-weight-bold">Surname</p>
              <p class="">{{ affiliate.surname || "-" }}</p>
            </div>
          </div>
          <div class="col">
            <div>
              <p class="font-weight-bold">Gender</p>
              <p class="">{{ affiliate.gender || "-" }}</p>
            </div>
          </div>
        </div>

        <hr style="border-style: dashed" />

        <div class="row">
          <div class="col-md-4">
            <p class="font-weight-bold">Affiliation</p>
            <p class="">{{ affiliate.affiliation || "-" }}</p>
          </div>
          <div class="col">
            <p class="font-weight-bold">Academia / Government / Industry</p>
            <p class="">{{ affiliate.industryAcademiaGovernment || "-" }}</p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-4">
            <p class="font-weight-bold">Position</p>
            <p class="">{{ affiliate.jobPosition || "-" }}</p>
          </div>
          <div class="col">
            <p class="font-weight-bold">Job Description</p>
            <p class="">{{ affiliate.jobDescription || "-" }}</p>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-4">
            <p class="font-weight-bold">Career Stage</p>
            <p class="">{{ affiliate.careerStage || "-" }}</p>
          </div>
          <div class="col-md-4">
            <p class="font-weight-bold">Country / Region</p>
            <p v-if="affiliate.country" class="">{{ affiliate.country.name }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <p class="font-weight-bold">Disability</p>
            <p class="">{{ affiliate.disability || "-" }}</p>
          </div>
        </div>
        <hr style="border-style: dashed" />
        <div class="row">
          <div class="col-12 col-md-4">
            <p class="font-weight-bold">Receive Newsletter</p>
            <p class="">{{ affiliate.newsletterSubscribed ? 'Yes' : "-" }}</p>
          </div>
        </div>

        <hr style="border-style: dashed" />
        <div class="row">
          <div class="col">
            <p class="font-weight-bold">Fields of Interest</p>
            <p v-if="hasFieldsOfInterest">
              <span v-for="(fi, key) in fieldsOfInterest" :key="key"
                ><span :title="fi.technicalCommittee.name">{{ fi.name }}</span>
                <span v-if="key < affiliate.fieldsOfInterest.length - 1">
                  ·
                </span>
              </span>
            </p>
            <p v-else>-</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Affiliates from '@/services/Api/Affiliates';
import AffiliatesPublic from '@/services/Api/Public/Affiliates';
import { IfacLoader, IfacAffiliatesHelpers } from '@ifac/ui';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      busy: true,
      affiliate: null,
    };
  },
  computed: {
    ...mapGetters({
      authed: 'auth/isAuthenticated',
    }),
    affiliateId() {
      return this.$route.params.id;
    },
    fullName() {
      return `${this.affiliate.title} ${this.affiliate.name} ${this.affiliate.surname}`;
    },
    fieldsOfInterest() {
      return IfacAffiliatesHelpers.sortFoiKeywords(this.affiliate?.fieldsOfInterest);
    },
    nameNoTitle() {
      return `${this.affiliate.name} ${this.affiliate.surname}`;
    },
    hasFieldsOfInterest() {
      return !!this.affiliate?.fieldsOfInterest?.length;
    },
  },
  methods: {
    async fetchAffiliate() {
      const {
        data: { data },
      } = await Affiliates.show(this.$route.params.id);

      this.affiliate = data;
    },
    async fetchAffiliatePublic() {
      const {
        data: { data },
      } = await AffiliatesPublic.show(this.$route.params.id);

      this.affiliate = data;
    },
    async fetchData() {
      this.busy = true;
      if (this.authed) {
        await this.fetchAffiliate();
      } else {
        await this.fetchAffiliatePublic();
      }
      this.busy = false;
    },
  },
  created() {
    this.fetchData();
  },
  components: {
    IfacLoader,
  },
};
</script>

<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
@import '@/assets/styles/base/_mixins.scss';

.general-details {
  hr {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}

</style>
